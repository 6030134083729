let $header = $(".header");
let position = $(window).scrollTop();

$(window).scroll(function () {
	let scroll = $(window).scrollTop();

	if (scroll > position) {
		$header.removeClass("active");
		if ($(window).scrollTop() > 700) {
			$header.addClass("hide");
		}
	} else {
		if ($(window).scrollTop() > 900) {
			$header.removeClass("hide");
			$header.addClass("active");
		} else {
			$header.removeClass("active");
			$header.removeClass("hide");
		}
	}
	position = scroll;
});
